import { Link } from "gatsby";
import * as React from "react";
import { Container, Row } from "react-bootstrap";
import Layout from "../components/Layout";
import { graphql } from "gatsby";

export default function pricingPage({ data }) {
  const Heading = data?.Heading?.nodes ?? [];
  const BogoPlans = data?.BogoPlans?.nodes ?? [];
  const BogoOption = data?.BogoOption?.nodes ?? [];
  const Merchant = data?.Merchant?.nodes ?? [];
  return (
    <Layout>
      <main>
        <Container>
          <section>
            <div className="col-xl-12 col-lg-12 col-md-12 py-2 py-xl-5 px-lg-5 py-md-5 px-xl-4 px-lg-4 px-md-4 px-2">
              <Row>
                {Heading.map((node, index) => {
                  return (
                    <>
                      <div className="col-xl-4 col-lg-4 col-md-12 pe-5">
                        <h2 className="fw-bold mb-4">{node.Title}</h2>
                        <h5 className="bogo-par fw-normal lh-32 fw-airlight  mb-4 pe-xl-5 pe-lg-5 pe-md-5 pe-0">
                          {node.FirstDescription}
                        </h5>
                        <p className="bogo-par fs-13 lh-22">
                          {node.SecondDescription}
                        </p>
                      </div>
                    </>
                  );
                })}
                <div className="col-xl-8 col-lg-8 col-md-12 pricing-Card">
                  <Row>
                    {BogoPlans.map((node, index) => {
                      return (
                        <>
                          {node?.BogoPlan?.length > 0 &&
                            node.BogoPlan.map((node2) => (
                              <div className="col-xl-4 col-lg-4 col-md-4 pe-4 mb-4">
                                <img
                                  src={node2.PlanImage}
                                  alt="star"
                                  className="mb-5 w-auto "
                                />
                                <h4 className="fw-bold mb-4">
                                  {node2.PlanTitle}
                                </h4>
                                <h4 className="fs-18 mb-4">
                                  {node2.PlanSubTitle}
                                </h4>
                                <p className="fs-14 mb-0 pe-3 fw-airlight">
                                  {node2.PlanDescription}{" "}
                                </p>

                                <Link
                                  to={node2.PlanCTALink}
                                  className="primary-color fw-bold fs-18 text-decoration-none d-block d-sm-none mt-4 mb-5"
                                >
                                  {node2.PlanCTAText}
                                </Link>
                              </div>
                            ))}
                        </>
                      );
                    })}

                    {BogoPlans.map((node2, index) => {
                      return (
                        <>
                          {node2?.BogoPlan?.length > 0 &&
                            node2.BogoPlan.map((node) => (
                              <div className="col-xl-4 col-lg-4 col-md-4 pe-4">
                                <Link
                                  to={node.PlanCTALink}
                                  className="primary-color fw-bold fs-18 text-decoration-none d-none d-sm-block"
                                >
                                  {node.PlanCTAText}
                                </Link>
                              </div>
                            ))}
                        </>
                      );
                    })}
                  </Row>
                </div>
              </Row>
            </div>
          </section>
          <section>
            <div className="col-xl-12 col-lg-12 col-md-12 py-xl-5 py-lg-5 py-md-5 pt-2 pb-5 px-4 my-5 ">
              <Row className="bg-light">
                {BogoOption.map((node2, index) => {
                  return (
                    <>
                      <div className="col-xl-4 col-lg-6 col-md-12 ps-0 me-lg-5">
                        <img
                          src={node2.TitleCTALinkImage}
                          alt="become bogo"
                          className="become-bogo"
                        />
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12 py-4 px-xl-5 ms-xl-5 ms-lg-5 ps-sm-4 pb-5 pb-xl-0 pb-lg-0 pb-md-0 mt-2 become-bogo">
                        <h5 className="fw-bold mb-4">
                          {node2.TitleCTALinkTitle}
                        </h5>
                        {node2?.BogoOptions?.length > 0 &&
                          node2.BogoOptions.map((node) => (
                            <p className="bogo-par fs-18 mb-1 fw-airlight ">
                              {node.PlanTitle}
                            </p>
                          ))}

                        <div className="Link-become pt-4">
                          <Link
                            to={node2.TitleCTALinkLink}
                            className="fs-18 link-color pb-2"
                          >
                            {node2.TitleCTALinkText}{" "}
                            <img
                              src="../img/arrow-right.svg"
                              alt="arrow right"
                              className="ms-3"
                            />
                          </Link>
                        </div>
                      </div>
                    </>
                  );
                })}
              </Row>
            </div>
          </section>
        </Container>
        <Container fluid={true} className="py-xl-5 px-lg-3">
          <section className="Light-bg my-xl-5 my-lg-5">
            <div className="col-xl-12 col-lg-12 col-md-12">
              <Row>
                {Merchant.map((node, index) => {
                  return (
                    <>
                      <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-12 ps-0 bg-last-section">
                        <img src={node.MerchantImage} alt="bg" />
                      </div>
                      <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-12 ps-lg-0 pt-xl-5 pt-lg-5 pb-sm-5 ps-sm-5 pt-sm-5 ps-xxl-0 ps-xl-5">
                        <h4 className="fs-40 fw-bold mb-5">
                          {node.MerchantTitle}
                        </h4>
                        <h5 className="bogo-par fw-normal lh-32 mb-5 fw-airlight pe-xl-5 pe-lg-5 pe-md-5 pe-0 me-xl-5 me-lg-5 me-md-5 me-0">
                          {node.MerchantDescription}
                        </h5>
                        <Link
                          to={node.MerchantCTALink}
                          className="fs-18 link-color pb-2"
                        >
                          {node.MerchantCTAText}{" "}
                          <img
                            src="../img/arrow-right.svg"
                            alt="arrow right"
                            className="ms-3"
                          />
                        </Link>
                      </div>
                    </>
                  );
                })}
              </Row>
            </div>
          </section>
        </Container>
      </main>
    </Layout>
  );
}
export const query = graphql`
  query {
    Heading: allPricingJson(filter: { Title: { ne: null } }) {
      nodes {
        Title
        SecondDescription
        FirstDescription
      }
    }
    BogoPlans: allPricingJson(
      filter: { BogoPlan: { elemMatch: { PlanTitle: { ne: null } } } }
    ) {
      nodes {
        BogoPlan {
          PlanCTALink
          PlanDescription
          PlanCTAText
          PlanImage
          PlanSubTitle
          PlanTitle
        }
      }
    }
    BogoOption: allPricingJson(filter: { TitleCTALinkTitle: { ne: null } }) {
      nodes {
        TitleCTALinkImage
        TitleCTALinkLink
        TitleCTALinkText
        TitleCTALinkTitle
        BogoOptions {
          PlanTitle
        }
      }
    }
    Merchant: allHomeJson(filter: { MerchantTitle: { ne: null } }) {
      nodes {
        MerchantCTALink
        MerchantCTAText
        MerchantDescription
        MerchantImage
        MerchantTitle
      }
    }
  }
`;
